import { createContext } from "react";
import jwt_decode from "jwt-decode";
// import setAuthToken from "../utils/setAuthToken";
let initialState = {
  currentUser: null,
  selectedUser: {},
  isAuth: false,
  activeStep:0,
  choosenPackage:null,
  config:{},
  project:{},
  projectSettings:{},
  price:0,
  customizationModalOpen:false,
  index:-1,
  eur:1,
  usd:1.132214,
  nextStep:null,
  completed:{},
  notification:{},
  useProject:{},
  setSelectedProject:null,
  isPaidProject:false,
  projects:[],
  loading:false,
  tenant:{},
  nextStepButtonDisabled:false,
  refresh_path:'/projects'
};
if (localStorage.token) {
  // Set headers
  // setAuthToken(localStorage.token); // I do not set token into axios defaulkt headers instead I add it at ery request that needs token
  // Get user info and exp
  let decoded = jwt_decode(localStorage.token);
  // Set user and isAuthenticated
  initialState.isAuth = true;
  initialState.currentUser = decoded;
  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    initialState.isAuth = false;
    initialState.currentUser = decoded;
  }
}
export const context = createContext(initialState);  
