import * as React from "react";
import { useState, useContext } from "react";
import { context } from "../../context/context";
import SignupForm from "./SignupForm";
import Api from "../../utils/Api";
import { Grid } from "@mui/material";
import SidePanel from "../Shared/SidePanel";
import { setNotification } from "../../utils/helpers";
export const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [organisationEmail, setOrgEmail] = useState("");
  const [name, setName] = useState("");
  const [useDifferentMail, setUseDifferentMail] = useState(false);
  const { dispatch } = useContext(context);
  const submit = async (e) => {
    e.preventDefault();
    try {
      await Api.registerUser({
        name,
        email,
        password,
        organisationEmail: organisationEmail || email,
      });
        setNotification(dispatch, {
          severity: "success",
          message: "Succesfully Created Tenant. You will recieve email with activation link. After succesfull activation, you can login into your account.",
          open: true,
          autoHideDuration: 4000,
        })
      // history.push("/login");
    } catch (err) {
      let errMessage = err.response?.data?.message;
      setNotification(dispatch, {
        severity: "error",
        message: errMessage,
        open: true,
        autoHideDuration: 4000,
      })
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <SignupForm
            handleSubmit={submit}
            setEmail={setEmail}
            email={email}
            setPassword={setPassword}
            password={password}
            setOrgEmail={setOrgEmail}
            organisationEmail={organisationEmail}
            setName={setName}
            name={name}
            useDifferentMail={useDifferentMail}
            setUseDifferentMail={setUseDifferentMail}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <SidePanel />
        </Grid>
      </Grid>
    </>
  );
};
