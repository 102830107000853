export const loginStyles = (theme) => ({
    root: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
    },
    field:{
      display:'block',
      margin:'10px auto',
      width:'100%'
    },
    button: {
      marginTop: "15px",
      marginBottom: "5px",
      background: theme.palette.primary.main,
    },
    links: {
      marginBottom: "10px",
      fontSize: "10px !important",
    },
    footer: {
      position: "absolute",
      top: "95vh",
    },
  });
  