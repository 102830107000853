import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import makeStyles from "@mui/styles/makeStyles";
import { ListItemIcon, ListItemText, List, ListItem } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  ul: {
    listStyleType: "none",
  },
  valid: {
    color: theme.palette.primary.main,
    verticalAlign: "middle",
    display: "block",
  },
  nonValid: {
    color: "red",
    verticalAlign: "middle",
  },
  listItem:{
      padding:'4px 10px'
  }
}));
export const PasswordValidation = ({ password, confirmpassword="", onCallback }) => {
  const classes = useStyles();
  const [length, setLength] = useState(false);
  const [capital, setCapital] = useState(false);
  const [lower, setLower] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const checkPasswordLength = (password) => {
    setLength(/(^.{7,})$/.test(password));
  };
  const checkPasswordCapitalLetter = (password) => {
    setCapital(/(.*[A-Z].*)/.test(password));
  };
  const checkPasswordLowerLetter = (password) => {
    setLower(/(.*[a-z].*)/.test(password));
  };
  const checkPasswordSymbols = (password) => {
    setSymbol(/(.*\W.*)/.test(password));
  };
  const checkconfirmpassword = (password, confirmpassword) => {
    return setConfirm(password === confirmpassword)
  };

  useEffect(() => {
    checkPasswordLength(password);
    checkPasswordLowerLetter(password);
    checkPasswordCapitalLetter(password);
    checkPasswordSymbols(password);
    confirmpassword && checkconfirmpassword(password, confirmpassword)
    return () => {};
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  useEffect(() => {
    onCallback(length && lower && capital && symbol)
    return () => {};
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, lower, capital, symbol]);
  return (
    <List
    component="nav"
  >
      <ListItem className={classes.listItem}>
      <ListItemIcon>{length ? <CheckIcon /> : <ClearIcon />}</ListItemIcon>
      <ListItemText
        className={length ? classes.valid : classes.nonValid}
        primary="At least 7 charachters length"
      />
      </ListItem>
      <ListItem className={classes.listItem}>
      <ListItemIcon>{lower ? <CheckIcon /> : <ClearIcon />}</ListItemIcon>
      <ListItemText
        className={lower ? classes.valid : classes.nonValid}
        primary="At least 1 lower letter"
      />
      </ListItem>
      <ListItem className={classes.listItem}>
      <ListItemIcon>{capital ? <CheckIcon /> : <ClearIcon />}</ListItemIcon>
      <ListItemText
        className={capital ? classes.valid : classes.nonValid}
        primary="At least 1 uppercase letter"
      />
      </ListItem>
      <ListItem className={classes.listItem}>
      <ListItemIcon>{symbol ? <CheckIcon /> : <ClearIcon />}</ListItemIcon>
      <ListItemText
        className={symbol ? classes.valid : classes.nonValid}
        primary="At least 1 symbol"
      />
      </ListItem>
      {confirmpassword && <ListItem className={classes.listItem}>
      <ListItemIcon>{confirm ? <CheckIcon /> : <ClearIcon />}</ListItemIcon>
      <ListItemText
        className={confirm ? classes.valid : classes.nonValid}
        primary="New and Confirmed password match"
      />
      </ListItem>}
    </List>
  );
};
