import  Api  from "./Api";

export const checkUrlError = (url) => {
  return (
    !url ||
    !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
      url
    )
  );
};
export const checkEmailError = (email) => {
  return !email || !/^\S+@\S+\.\S+$/.test(email);
};
export const checkWaPhoneNumber = (phoneNumber) => {
  return (
    !phoneNumber ||
    !/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/.test(
      phoneNumber
    )
  );
};
export const checkIsFormValid = (state, fields) => {
  let notValid = false;
  for (let i = 0; i < fields.length; i++) {
    if (!state[fields[i]]) {
      notValid = true;
      break;
    }
    if((fields[i] === 'email' || fields[i] === 'organizationEmail'|| fields[i] === 'organisationEmail') && checkEmailError(state[fields[i]])){
      notValid = true;
      break;
    }
    if((fields[i] === 'url' && checkUrlError(state[fields[i]]))){
      notValid = true;
      break;
    }
  }
  return notValid;
};


export const setLoading = (dispatch, value) => {
  dispatch({type:'SET_LOADING', payload:value})
};

export const setNotification = (dispatch, payload) => {
  dispatch({
    type: "SET_NOTIFICATION",
    payload: payload,
  });
};


export const downloadFile = (src) => {
  Api.getData(src, null, null)
    .then(response => {
      response.arrayBuffer().then(function(buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.png"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch(err => {
      console.log(err);
    });
}
