import React from "react";
import { Snackbar, Fade, Slide, Grow, Alert } from "@mui/material";
import { MemoComponent } from "../HOC/MemoComponent";
import { useTraceUpdate } from "../../hooks/useTrace";
const transitionMap = { fade: Fade, grow: Grow, slide: Slide };

const MUIAlert = React.forwardRef(function MUIAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});
let anchorOriginObj = { vertical: 'top', horizontal: 'right' }
let styleObj = {}
const NotificationComponent = ({
  anchorOrigin = anchorOriginObj,
  open = false,
  handleClose,
  message,
  transition = "slide",
  autoHideDuration = null,
  severity = "success",
  style = styleObj,
}) => {
  useTraceUpdate({anchorOrigin,
  open,
  handleClose,
  message,
  transition,
  autoHideDuration,
  severity,
  style,})
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      TransitionComponent={transitionMap[transition]}
      anchorOrigin={anchorOrigin}
      message={message}
      key={new Date().getTime()}
      style={style}
    >
      <MUIAlert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </MUIAlert>
    </Snackbar>
  );
};

export const Notification = MemoComponent(NotificationComponent)
