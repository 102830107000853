import axios from 'axios'

export default class HttpClient {

constructor(baseURL, apiKey, headers) {
    this.instance = axios.create({
      baseURL
    })
    this.headers = headers
    this.initializeResponseInterceptor()
    this.initializeRequestInterceptor(apiKey)
  }

  initializeRequestInterceptor = (apiKey) => {
    this.instance.interceptors.request.use(req => {
        if(apiKey)req.headers['Authorization'] = 'Basic ' + apiKey
        req.headers['Content-Type'] = 'application/json';
        req.headers.common = {...req.headers.common, ...this.headers}
        return req;
    }
    )
  }
  initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    )
  }

   _handleResponse = (response) => response

  _handleError = (error) => Promise.reject(error)
}
