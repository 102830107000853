export const loaderStyles = (theme) => ({
  loading: {
    display: "block",
    position: "absolute",
    width: "100px",
    top: "50%",
    left: "50%",
    opacity: 10000,
    height: "100px",
    zIndex: "10000000000000",
  },
  loadingDiv1: {
    background: theme.palette.primary.dark,
    opacity: 1,
    borderRadius: "50%",
    // position: "absolute",

    animation: `$loading 1.4s ${theme.transitions.easing.easeInOut} infinite`,
    transform: "scale(1)",
  },
  loadingDiv2: {
    background: theme.palette.primary.dark,
    opacity: 1,
    borderRadius: "50%",
    animation: `$loading 1.4s ${theme.transitions.easing.easeInOut} infinite`,
    animationDelay: "-.2s",
    transform: "scale(1)",
  },

  "@keyframes loading": {
    "0%": {
      top: "58px",
      left: "58px",
      width: "30px",
      height: "30px",
      opacity: 1,
    },
    "100%": {
      top: "-1px",
      left: "-1px",
      width: "30px",
      height: "30px",
      opacity: 0,
      transform: "scale(4)",
    },
  },
  showComponent: {
    display: "none",
  },

  disableClick: {
    pointerEvents: "none",
  },
});
