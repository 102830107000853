import React, { useContext, useState } from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import Api from "../../utils/Api";
import { context } from "../../context/context";
import { Grid } from "@mui/material";
import SidePanel from "../Shared/SidePanel";
import { setNotification } from "../../utils/helpers";
export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const { dispatch } = useContext(context);
  const submit = async (e) => {
    e.preventDefault();
    try {
      await Api.addData('/api/v1/new-pass', null, { email });
      setNotification(dispatch, {
        severity: "info",
        message: "You will recieve email with information how to reset password.",
        open: true,
        autoHideDuration: 4000,
      })
      
    } catch (err) {
        let errMessage = err.response?.data?.message;
      setNotification(dispatch, {
        severity: "error",
        message: errMessage,
        open: true,
        autoHideDuration: 4000,
      })
    }
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={5} xl={5}>
        <ForgotPasswordForm
          handleSubmit={submit}
          setEmail={setEmail}
          email={email}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={7} xl={7}>
        <SidePanel />
      </Grid>
    </Grid>
  );
};