import React, { Suspense, useContext, useReducer, useEffect, useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { Splash } from './views/Login';
import { context } from './context/context';
import reducer from './reducer/reducer';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import withRoot from './withRoot';
import { SignupPage } from './views/Signup/Signup';
import { Notification } from './components/Snackbar/Snackbar';
import LoaderInitial from './components/Loader/LoaderInitial';
import Api from './utils/Api';
import { ForgotPassword } from './views/Login/ForgotPassword';
import { SplashResetScreen } from './views/Login/SplashResetScreen';
import { LoginPage } from './views/Login/Login';
// import Api from "./utils/Api";
// import MiniDrawer from "./layouts/Admin";
import GlobalStyles from '@mui/material/GlobalStyles';
import { FacebookLogin } from './views/Login/FacebookLogin';

// const Admin = React.lazy(() => import("./layouts/Admin2"));
const MiniDrawer = React.lazy(() => import('./layouts/Admin'));
const App = () => {
  const initialState = useContext(context);
  const [state, dispatch] = useReducer(reducer, initialState);

  const setShowNotification = () => {
    dispatch({ type: 'CLOSE_NOTIFICATION' });
  };

  // const getData = async () => {
  //   try{
  //     let response = await Api.getCurrencyValue('https://freecurrencyapi.net/api/v2/latest', `?apikey=${process.env.REACT_APP_CURRENCY_KEY}&base_currency=EUR`)
  //     dispatch({type:'SET_CURRENCY', payload:response.data.data.USD})
  //   }catch(err){
  //     console.log(err)
  //   }
  // }

  const getUser = async () => {
    try {
      let response = await Api.getData('/api/v1/userById', localStorage.token);
      dispatch({ type: 'SET_USER', payload: response.data });
    } catch (err) {
      console.log('error', err);
    }
  };
  const getTenant = async () => {
    try {
      let response = await Api.getData('/portal/v1/tenantById', localStorage.token);
      dispatch({ type: 'SET_TENANT', payload: response.data });
    } catch (err) {
      console.log('error', err);
    }
  };
  const getAllData = async () => {
    getTenant();
    getUser();
  };
  useEffect(() => {
    getAllData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const memoizedSetShowNotification = useCallback(() => {
    setShowNotification(false);
  }, []);
  return (
    <Suspense fallback={<LoaderInitial />}>
      <context.Provider value={{ state, dispatch }}>
        <GlobalStyles
          styles={
            {
              // input: {
              //   padding: "6px 16px!important"
              // }
            }
          }
        />
        <StyledEngineProvider injectFirst>
          <Switch>
            <Route path="/login" render={() => <Splash component={LoginPage} />} />
            <Route path="/signup" component={SignupPage} />
            <Route path="/new-pass" render={() => <Splash component={ForgotPassword} />} />
            <Route path="/reset-pass/:token" component={SplashResetScreen} />
            <Route path="/fb-logins/:kind" component={FacebookLogin} />
            <ProtectedRoute path="/" component={MiniDrawer} />
          </Switch>
          <Notification {...state.notification} handleClose={memoizedSetShowNotification} />
        </StyledEngineProvider>
      </context.Provider>
    </Suspense>
  );
};

export default withRoot(App);
