import axios from "axios";
import httpClient from "./http";
let BASE_URL = process.env.REACT_APP_BASEURL; //FIXME add urls for dev and prod 
let BASE_URL_1 = process.env.REACT_APP_BASEURL; //FIXME add urls for dev and prod 

axios.defaults.baseUrl = BASE_URL;

class AppRequests extends httpClient {

  postRequest = (url, data) => {
    return this.instance({
      method: "post",
      url: url,
      data: data,
    });
  };
  patchRequests = (url, data) => {
    return this.instance({
      method: "patch",
      url: url,
      data: data,
    });
  };
  deleteRequests = (url) => {
    return this.instance({
      method: "delete",
      url: url,
    });
  };

  getData = (url) =>
    this.instance({
      method: "get",
      url: url,
    });
}

class Api {
  headers
  constructor(headers = {}) {
    this.headers = headers
  }
  static async loginUser(creds) {
    let authReqObj = new AppRequests(BASE_URL,"", this.headers);
    try {
      return authReqObj.postRequest(`/api/v1/login`, creds);
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
  static async registerUser(tenant) {
    try {
      return axios.post(`${BASE_URL}/api/v1/tenant`, tenant);
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
  static async updateUser(user) {
    try {
      const { data } = await axios.post(`${BASE_URL}/update-user`, user);
      return data;
    } catch (e) {
      return e.response.data;
    }
  }
  static async getCurrencyValue(baseUrl, urlParams) {
    let currencyObj = new AppRequests(baseUrl);
    try {
      return currencyObj.getData(urlParams);
    } catch (err) {
      console.log(err);
      return err.response.data;
    }
  }
  static async createProjectAndSubscribe(data) {
    let authReqObj = new AppRequests(BASE_URL);
    try {
      return authReqObj.postRequest(`/api/v1/create-checkout-session`, data);
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
  static async subscriptionDetails(data) {
    let authReqObj = new AppRequests(BASE_URL);
    try {
      return authReqObj.postRequest(`/api/v1/create-portal-session`, data);
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
  static async getData(url, apiKey, headers) {
    let authReqObj = new AppRequests(BASE_URL, apiKey, headers);
    try {
      return authReqObj.getData(url);
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
  static async addData(url, apiKey, data, headers) {
    let authReqObj = new AppRequests(BASE_URL, apiKey, headers);
    try {
      return authReqObj.postRequest(url, data);
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
  static async updateData(url, apiKey, data, headers) {
    let authReqObj = new AppRequests(BASE_URL, apiKey, headers);
    try {
      return authReqObj.patchRequests(url, data);
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
  static async deleteData(url, apiKey, headers) {
    let authReqObj = new AppRequests(BASE_URL, apiKey, headers);
    try {
      return authReqObj.deleteRequests(url);
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
  static async addWhatsappAcount(url, apiKey, headers) {
    let authReqObj = new AppRequests(BASE_URL_1, apiKey, headers);
    try {
      return authReqObj.postRequest(url, {});
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
  
}

export default Api;
