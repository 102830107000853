import * as React from "react";
import {
  Avatar,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CssBaseline,
  Link,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Footer from "../Shared/Footer";
import { checkIsFormValid } from "../../utils/helpers";
import { PasswordValidation } from "../../components/Validation/PasswordValidation";

// const Copyright = (props) => {
//   return (
//     <Typography variant="body2" color="inherit" align="center" {...props}>
//       {"Copyright © "}
//       <Link color="inherit" href="https://mailbook.io">
//         Mailbook
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// };

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  field: {
    display: "block",
    margin: "10px auto",
    width: "100%",
  },
  button: {
    marginTop: "15px",
    marginBottom: "5px",
    background: theme.palette.primary.main,
  },
  links: {
    marginBottom: "10px",
    fontSize: "10px !important",
    textAlign: "center",
  },
  checkbox: {
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  footer: {
    position: "absolute",
    top: "95vh",
  },
});
const SignupForm = ({
  handleSubmit,
  setEmail,
  email,
  setPassword,
  password,
  setName,
  name,
  setOrgEmail,
  organisationEmail,
  setUseDifferentMail,
  useDifferentMail,
  classes,
}) => {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [displayValidationOfPassword, setDisplayValidationOfPassword] = React.useState(false);
  const [validPassword, setValidPassword] = React.useState(false);
  React.useEffect(() => {
    let fields = !useDifferentMail
      ? ["name", "email", "password"]
      : ["name", "email", "password", "organisationEmail"];
    setIsDisabled(checkIsFormValid({email, password, name, organisationEmail}, fields));
    return () => {};
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password, name, organisationEmail]);
  return (
    <Grid spacing={0} className={classes.root}>
      <CssBaseline />
      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Register Organization
      </Typography>
      <form component="form" onSubmit={handleSubmit}>
        <TextField
          className={classes.field}
          margin="normal"
          size="small"
          fullWidth
          id="name"
          label="Name"
          name="name"
          type="text"
          value={name}
          autoFocus
          error={!name}
          helpertext={!name ? "Required" : ""}
          autoComplete="current-name"
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
        size="small"
          className={classes.field}
          margin="normal"
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          error={!email}
          helpertext={!email ? "Required" : ""}
          onChange={(e) => setEmail(e.target.value)}
        />
        {useDifferentMail ? (
          <TextField
          size="small"
            className={classes.field}
            margin="normal"
            fullWidth
            label="Organization Email Address"
            id="organisationeEmail"
            name="organisationeEmail"
            value={organisationEmail}
            autoComplete="organisationeEmail"
            error={!organisationEmail}
            helpertext={!organisationEmail ? "Required" : ""}
            onChange={(e) => setOrgEmail(e.target.value)}
          />
        ) : null}
        <TextField
        size="small"
          className={classes.field}
          margin="normal"
          fullWidth
          id="password"
          label="Password"
          name="password"
          type="password"
          value={password}
          autoComplete="current-password"
          error={!password}
          helpertext={!password ? "Required" : ""}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => setDisplayValidationOfPassword(false)}
          onFocus={(e) => setDisplayValidationOfPassword(true)}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={useDifferentMail}
                onChange={(e) => setUseDifferentMail(e.target.checked)}
                className={classes.checkbox}
              />
            }
            label="Use different organization email"
          />
        </FormGroup>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2}}
          className={classes.button}
          disabled={isDisabled || !validPassword}
        >
          Sign Up
        </Button>
        <Grid container className={classes.links}>
          <Grid item xs={12}>
            <Link component={RouterLink} to="/login" variant="body2">
              {"Already have an account? Sign In"}
            </Link>
          </Grid>
        </Grid>
      </form>
      {displayValidationOfPassword && <PasswordValidation password={password} onCallback={(value) => setValidPassword(value)}/>}
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="flex-start"
        className={classes.footer}
        sx={{ display: { xs: "none", sm: "block" } }}
      >
        <Footer />
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(SignupForm);
