import * as React from "react";
import { Avatar, Button, CssBaseline, Link, Grid, TextField, Typography } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { withStyles } from "@mui/styles";
import Footer from "../Shared/Footer";
import { checkIsFormValid } from "../../utils/helpers";
import { loginStyles } from "./styles";

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="inherit" align="center" {...props}>
//       {"Copyright © "}
//       <Link color="inherit" href="https://mailbook.io">
//         Mailbook
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const styles = (theme) => (
  loginStyles(theme)
);


let fields = ["email", "password"];
const SignIn = ({
  handleSubmit,
  setEmail,
  email,
  setPassword,
  password,
  classes,
}) => {
  const [isDisabled, setIsDisabled] = React.useState(false);
  React.useEffect(() => {
    setIsDisabled(checkIsFormValid({email, password}, fields));
    return () => {};
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);
  return (
    <Grid className={classes.root}>
      <CssBaseline />

      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          className={classes.field}
          size="small" 
          margin="normal"
          id="email"
          label="Email Address"
          name="email"
          value={email}
          error={!email}
          helpertext={!email ? "Required" : ""}
          inputProps={{
            autoComplete:"new-password"
          }}
          fullWidth
          
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          className={classes.field}
          size="small" 
          margin="normal"
          id="password"
          label="Password"
          name="password"
          type="password"
          value={password}
          error={!password}
          helpertext={!password ? "Required" : ""}
          inputProps={{
            autoComplete:"new-password"
          }}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          className={classes.button}
          disabled={isDisabled}
        >
          Sign In
        </Button>
        <Grid container className={classes.links}>
          <Grid item xs={5}>
            <Link component={RouterLink} to="/new-pass" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
            <Link component={RouterLink} to="/signup" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </form>

      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="flex-start"
        className={classes.footer}
        sx={{ display: { xs: "none", sm: "block" } }}
      >
        <Footer display={{ xs: "none", sm: "block" }} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SignIn);
