import React, { useContext, useState } from "react";
import SignIn from "./LoginForm";
import Api from "../../utils/Api";
import { context } from "../../context/context";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../utils/setAuthToken";
import { Grid } from "@mui/material";
import SidePanel from "../Shared/SidePanel";
import { setNotification } from "../../utils/helpers";
export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { dispatch } = useContext(context);
  const submit = async (e) => {
    e.preventDefault();
    try {
      let {
        data: { token },
      } = await Api.loginUser({ email, password });
      localStorage.setItem("token", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch({ type: "LOGIN_USER", payload: decoded });
    } catch (err) {
      setNotification(dispatch, {
        severity: "error",
        message: "Invalid Email Or Password",
        open: true,
        autoHideDuration: 4000,
      })
    }
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} xl={6}>
        <SignIn
          handleSubmit={submit}
          setEmail={setEmail}
          email={email}
          setPassword={setPassword}
          password={password}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} xl={6}>
        <SidePanel />
      </Grid>
    </Grid>
  );
};
