import React from "react";
import { withStyles } from "@mui/styles";
import { loaderStyles } from "./styles";

const styles = (theme) => {
  return loaderStyles(theme)
};

  const Loader = ({ classes, loading }) => {
    let content = (
      <div>
          <div>
            <div className={classes.loading}>
              <div className={classes.loadingDiv1}>
              <div className={classes.loadingDiv2}></div>
              </div>
            </div>
          </div>
      </div>
    );

    return content;
  };
  export default  withStyles(styles)(Loader);


