import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import green from "@mui/material/colors/green";
import CssBaseline from "@mui/material/CssBaseline";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme({
  palette: {
    primary:{
      light:'#a3b4d4',
      main: '#2f5392',
      dark: '#162a4e'
    },
    background:{
      light:'linear-gradient(55deg, #162a4e 30%, #2f5392 70%, #a3b4d4 90%)',
      main: '#2f5392',
      dark: '#162a4e'
    },
    secondary: {
      light: green[300],
      main: green[500],
      dark: green[700]
    },
    third:{
      light:'#3f6dbf',
      main: '#2f5392',
      dark: '#162a4e'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: "#2f5392"
    },
   
    packageTitle:{
      color:'white',
      fontSize:'26px',
      display:'block',
      marginBottom:'2vh'
    },
    packageSubTitle:{
      color:'white',
      fontSize:'18px'
    },
    classicTypography:{
      color:'#2f5392',
      fontSize:'24px',
      zIndex:50,
      display:'flex',
      justifyContent:'center'
    },
    priceInfo:{
      color:'#3f6dbf',
      fontSize:'12px',
      display:'block',
      marginBottom:'2vh',
      marginTop:'-2vh'
    },
    darkTitle:{
      color:'black',
      display:'block',
      fontSize:'26px',
      marginBottom:'2vh'
    },
    darkSubtitle:{
      color:'black',
      display:'block',
      fontSize:'18px'
    },
    lightSubtitle:{
      color:'white',
      // display:'block',
      fontSize:'24px',
      margin:0,
      zIndex:50,
      display:'flex',
      justifyContent:'center'
    },
    useNextVariants: true,
    defaultProps: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span',
      },
    },
  },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0.2, 0.8, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        {/* https://material-ui.com/getting-started/usage/#cssbaseline */}
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;