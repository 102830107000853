import React from "react";
import { Grid, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import banner from "../../assets/img/banner-thumb.png";
import logo from "../../assets/img/logo.png";
import Footer from "./Footer";

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    background: theme.palette.background.light,
    color: "white",
    position:'relative',
    '@media (max-width:780px)' : {
      // width: '80%'
      height: "auto",
    }
  },
  textBox:{
    wordWrap: 'break-word',
    fontStretch: 'expanded',
    padding:'1vw',
    '@media (max-width:780px)' : {
      // width: '80%'
      textAlign: "center",
    }

  },
  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    height: "70vh",
  },
  spanBig: {
    fontSize: "45px",
    fontWeight: 700,
    '@media (max-width:780px)' : {
      // width: '80%'
      fontSize: "30px",
      fontWeight: 700,
    }
  },
  spanSmall: {
    fontSize: "20px",
    '@media (max-width:780px)' : {
      // width: '80%'
      fontSize: "15px",
    }
  },
  footer:{
    position: "absolute",
    top: "70vh",
  }
});

const SidePanel = ({ classes }) => {
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={12} xl={12}>
        <Grid item xs={12} sm={4} md={4} xl={4}>
          <img alt="Logo" src={logo} className={classes.logo} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={6} xl={6} className={classes.textBox}>
        <span className={classes.spanBig}>
          Communication platform (CPaaS) for omnichannel engagement
        </span>
        <br /> 
        <br />
        <span className={classes.spanSmall}>
          Different channels communications such as, Viber Business, WhatsApp
          Business, E-mail, Microsoft Office 365, Google Workspace, Facebook
          Messenger, Twitter, Mass campaign...
        </span>
        <br /> 
        <br />
        <Button style={{color: 'white', top: "95vh", position: "absolute"}}
              variant="textButton"
              aria-label="Watch Video"
              onClick={() =>
                window.open("https://calendly.com/mailbook/30min", "_blank")
              }
            >Book a meeting!</Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6} xl={6}  display={{ sm: 'block', xs: 'none' }}>
        <img alt="Baner" src={banner} className={classes.img}></img>
      </Grid>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="flex-start"
        className={classes.footer}
        sx={{ display: { xs: "block", sm: "none" }}}
      >
      <Footer/>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SidePanel);
