export default function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case "LOGIN_USER":
      return {
        ...state,
        isAuth: true,
        currentUser: payload,
      };
    case "SET_USER":
      return {
        ...state,
        selectedUser: payload,
      };
    case "UPDATE_USER":
      return {
        ...state,
        currentUser:{
          ...state.currentUser,
          [payload.name]: payload.value,
        }
      };
    case "SIGNOUT_USER":
      return {
        ...state,
        isAuth: false,
        currentUser: null,
      };
      
    case "CHANGE_STEPPER":
      return {
        ...state,
        activeStep: payload,
      };
    case "CHOOSE_PACKAGE":
      return {
        ...state,
        choosenPackage: payload.choosenPackage || state.choosenPackage,
        config: payload.config,
        price: payload.price || state.price,
      };
    case "SET_PRICE":
      return {
        ...state,
        price: payload,
      };
    case "SET_COMPLETED":
      return {
        ...state,
        completed: payload,
      };

    case "TOGGLE_MODAL":
      return {
        ...state,
        customizationModalOpen: payload.modal,
        index:payload.index
      };
    case "SET_CURRENCY":
      return {
        ...state,
        usd:payload
      };
    case "SET_PROJECT":
      return {
        ...state,
        project:{
          ...state.project,
          [payload.name]:payload.value
        }
      };
    case "SET_PROJECT_WEBHOOK":
      return {
        ...state,
        project:{
          ...state.project,
          webhook:{
            ...state.webhook,
            [payload.name]:payload.value
          }
        }
      };
    case "SET_FULL_PROJECT":
      return {
        ...state,
        project:payload
      };
    case "SET_FULL_SETTINGS":
      return {
        ...state,
        projectSettings:payload
      };
    case "SET_FULL_CONFIG":
      return {
        ...state,
        config:payload
      };
    case "RESET_STATE":
      return {
        ...state,
        choosenPackage:null,
        activeStep:0,
        completed:{},
        config:{},
        price:0,
        project:{},
        projectSettings:{}
      };
    case "UPDATE_PROJECT_STATE":
      return {
        ...state,
        ...payload
      };
    case "SET_NEXT_STEP":
      return {
        ...state,
        nextStep:payload
      };
    case "SET_ALL_STATE":
      return {
        ...payload
      };
    case "SET_PROJECT_SETTINGS":
      return {
        ...state,
        projectSettings:{...state.projectSettings, modules:payload}
      };
    case "SET_NOTIFICATION":
      return {
        ...state,
        notification:payload
      };
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        notification:null
      };
    case "USE_PROJECT":
      return {
        ...state,
        useProject:payload
      };
    case "SET_SELECTED_PROJECT":
      return {
        ...state,
        setSelectedProject:payload
      };
    case "IS_PAID_PROJECT": 
      return {
        ...state,
        isPaidProject:state.project.paid
      }
    case "SET_PROJECTS": 
      return {
        ...state,
        projects:payload
      }
    case "SET_LOADING": 
      return {
        ...state,
        loading:payload
      }
    case "SET_TENANT": 
      return {
        ...state,
        tenant:payload
      }
    case "UPDATE_TENANT":
      return {
        ...state,
        tenant:{
          ...state.tenant,
          [payload.name]:payload.value
        }
      };
    case "NEXT_STEP_BUTTON_DISABLED":
      return {
        ...state,
       nextStepButtonDisabled:payload.value
      };
    case "SET_REFRESH_PATH":
    return {
      ...state,
      refresh_path:payload
    };
    default:
      return state;
  }
}
