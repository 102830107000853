import * as React from "react";
import { Avatar, Button, CssBaseline, Link, Grid, TextField, Typography } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { withStyles } from "@mui/styles";
import Footer from "../Shared/Footer";
import { checkIsFormValid } from "../../utils/helpers";
import { loginStyles } from "./styles";

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="inherit" align="center" {...props}>
//       {"Copyright © "}
//       <Link color="inherit" href="https://mailbook.io">
//         Mailbook
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const styles = (theme) => {
  return loginStyles(theme)
};

let fields = ["email"];

const ForgotPasswordForm = ({
  handleSubmit,
  setEmail,
  email,
  classes,
}) => {
  const [isDisabled, setIsDisabled] = React.useState(false);
  React.useEffect(() => {
    setIsDisabled(checkIsFormValid({email}, fields));
    return () => {};
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);
  return (
    <Grid spacing={0} className={classes.root}>
      <CssBaseline />

      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Forgot Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          className={classes.field}
          margin="normal"
          id="email"
          label="Email Address"
          name="email"
          value={email}
          error={!email}
          helpertext={!email ? "Required" : ""}
          inputProps={{
            autoComplete:"new-password"
          }}
          fullWidth
          
          onChange={(e) => setEmail(e.target.value)}
        />
        
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          className={classes.button}
          disabled={isDisabled}
        >
          Send Reset Email
        </Button>
        <Grid container className={classes.links}>
          <Grid item xs={5}>
            <Link component={RouterLink} to="/login" variant="body2">
              Back to Login?
            </Link>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
            <Link component={RouterLink} to="/signup" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </form>

      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="flex-start"
        className={classes.footer}
        sx={{ display: { xs: "none", sm: "block" } }}
      >
        <Footer display={{ xs: "none", sm: "block" }} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ForgotPasswordForm);
