import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import { withStyles } from "@mui/styles";
const styles = (theme) => ({
  root: {},
});
function Copyright(props) {
  return (
    <Typography variant="body2" color="primary.main" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://mailbook.io">
        Mailbook
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const Footer = ({ classes }) => {
  return (
    <Grid rowSpacing={6}>
      <Grid
        container
        className={classes.root}
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        <Grid item>
          <Link href="https://mailbook.io/privacy.html" target='_blank'>Privacy Policy</Link>
        </Grid>
        <Grid item>
          <Link href="https://mailbook.io/refund.html" target='_blank'>Refund Policy</Link>
        </Grid>
        <Grid item>
          <Link href="https://mailbook.io/delivery.html" target='_blank'>Delivery Policy</Link>
        </Grid>
        <Grid item>
          <Link href="https://mailbook.io/gdpr.html" target='_blank'>GDPR Compliance</Link>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
          <Copyright/>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Footer);
